import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import logo from "../../assets/images/knolar-logo.png"
import PropTypes from "prop-types"

const LoginFormHeader = ({ headerMessage, headerErrorMessage }) => {
  const intl = useIntl()

  return (
    <div className="form-header">
      <div className="image-wrapper">
        <img src={logo} alt="logo" />
      </div>
      {headerMessage ? (
        <p className="header-message">
          {intl.formatMessage({ id: headerMessage })}
        </p>
      ) : (
        ""
      )}
      {headerErrorMessage ? (
        <p className="error-color">
          {intl.formatMessage({ id: headerErrorMessage })}
        </p>
      ) : (
        ""
      )}
    </div>
  )
}

LoginFormHeader.propTypes = {
  headerMessage: PropTypes.string,
  headerErrorMessage: PropTypes.string,
}

export default LoginFormHeader
