import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useIntl } from "gatsby-plugin-intl"
import LoginFormHeader from "./loginFormHeader"

import { Button, Form, Layout, Row, Col } from "antd"
import KnolarInputPassword from "../knolarInputPassword"
import KnolarInput from "../knolarInput"
import useStateObject from "../../hooks/useStateObject"

const LoginNewPasswordForm = ({
  form,
  isRecoveryCode,
  headerMessage,
  headerErrorMessage,
  onSubmit,
  processing,
}) => {
  const intl = useIntl()
  const [state, setState] = useStateObject({
    headerErrorMessage,
  })
  const { getFieldValue } = form

  useEffect(() => {
    setState({
      headerErrorMessage,
    })
    // eslint-disable-next-line
  }, [headerErrorMessage])

  const handleSubmit = ev => {
    ev.preventDefault()
    form.validateFields((err, { password, recoverycode }) => {
      if (!err) {
        onSubmit({ password, recoverycode })
      }
    })
  }

  const handleChange = () => {
    if (state.headerErrorMessage) {
      setState({
        headerErrorMessage: "",
      })
    }
  }

  const password = getFieldValue("password")
  const confirmPassword = getFieldValue("confirm-password")
  const success = !!(
    password &&
    confirmPassword &&
    password === confirmPassword
  )

  return (
    <Layout className="login-page">
      <Row className="container">
        <Col
          span={8}
          offset={8}
          align="center"
          className="first-password-container"
        >
          <LoginFormHeader
            headerMessage={headerMessage}
            headerErrorMessage={state.headerErrorMessage}
          />
          <Form
            onSubmit={handleSubmit}
            className="login-form components-form-demo-normal-login"
          >
            {isRecoveryCode ? (
              <KnolarInput itemId="recoverycode" form={form} />
            ) : (
              ""
            )}
            <KnolarInputPassword
              itemId="password"
              itemLabel="new-password"
              form={form}
              onChange={handleChange}
            />
            <KnolarInputPassword
              itemId="confirm-password"
              form={form}
              success={success}
              onChange={handleChange}
            />
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                loading={processing}
              >
                {intl.formatMessage({ id: "login-update-password" })}
              </Button>
            </Form.Item>
          </Form>
          <Row className="footer">
            <Col span={10} offset={2}>
              {`® ${new Date().getFullYear()} ${intl.formatMessage({
                id: "copyright-text",
              })}`}
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  )
}

LoginNewPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isRecoveryCode: PropTypes.bool.isRequired,
  headerMessage: PropTypes.string.isRequired,
  headerErrorMessage: PropTypes.string.isRequired,
  processing: PropTypes.bool.isRequired,
}

export default Form.create({ name: "new_password_form" })(LoginNewPasswordForm)
