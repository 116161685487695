import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Button, Checkbox, Col, Form, Row } from "antd"
import { useIntl } from "gatsby-plugin-intl"

import laptop from "../../assets/images/img_laptop.png"

import LoginFormHeader from "./loginFormHeader"
import KnolarInputPassword from "../knolarInputPassword"
import KnolarInput from "../knolarInput"
import { recoverPassword, resendPassword } from "../../services/auth"
import useStateObject from "../../hooks/useStateObject"

const LoginForm = ({
  onSubmit,
  form,
  headerMessage,
  headerErrorMessage,
  processing,
  onSSO,
}) => {
  const intl = useIntl()
  const [state, setState] = useStateObject({
    showPassword: false,
    forgotPassword: false,
    recoverPasswordMessage: {
      message: "",
      error: true,
    },
    rememberMe: false,
  })

  useEffect(() => {
    const username = localStorage.getItem("username")
    const password = localStorage.getItem("password")
    form.setFieldsValue({ username, password })
    if (username && password) {
      setState({ rememberMe: true })
    }
  }, [])

  const handleSubmit = ev => {
    ev.preventDefault()
    form.validateFields((err, { username, password }) => {
      if (state.rememberMe) {
        localStorage.setItem("username", username)
        localStorage.setItem("password", password)
      } else {
        localStorage.removeItem("username")
        localStorage.removeItem("password")
      }
      if (!err && typeof onSubmit === "function") {
        onSubmit({ username, password })
      }
    })
  }

  const handleRecoverPassword = () => {
    const { validateFields, getFieldError } = form
    validateFields()
    if (!getFieldError("username")) {
      recoverPassword(form.getFieldValue("username"))
        .then(() =>
          setState({
            recoverPasswordMessage: {
              message: intl.formatMessage({
                id: "password-recovery-message",
              }),
              error: false,
            },
          })
        )
        .catch(err => {
          switch (err.code) {
            case "UserNotFoundException":
              setState({
                recoverPasswordMessage: {
                  message: intl.formatMessage({
                    id: "password-recovery-error-message-not-found",
                  }),
                  error: true,
                },
              })
              break
            case "LimitExceededException":
              setState({
                recoverPasswordMessage: {
                  message: intl.formatMessage({
                    id: "password-recovery-error-message-too-much-attempts",
                  }),
                  error: true,
                },
              })
              break
            case "NotAuthorizedException":
              resendPassword(form.getFieldValue("username"))
                .then(() => {
                  setState({
                    recoverPasswordMessage: {
                      message: intl.formatMessage({
                        id: "password-recovery-message",
                      }),
                      error: false,
                    },
                  })
                })
                .catch(() => {
                  setState({
                    recoverPasswordMessage: {
                      message: intl.formatMessage({
                        id: "password-recovery-error-message",
                      }),
                      error: true,
                    },
                  })
                })
              break
            default:
              setState({
                recoverPasswordMessage: {
                  message: intl.formatMessage({
                    id: "password-recovery-error-message",
                  }),
                  error: true,
                },
              })
          }
        })
    }
  }

  return (
    <div className="login-page">
      <Row className="container">
        <Col span={13} className="login-container" align="center">
          <Row>
            <Col span={12} offset={6} align="center" className="login-form">
              <LoginFormHeader
                headerMessage={headerMessage}
                headerErrorMessage={headerErrorMessage}
              />
              <Form
                onSubmit={handleSubmit}
                className="login-form components-form-demo-normal-login"
              >
                <KnolarInput itemId="username" form={form} />
                {state.forgotPassword ? (
                  <div>
                    <div
                      className={`recovery-message ${
                        state.recoverPasswordMessage.error ? "error" : ""
                      }`}
                    >
                      {state.recoverPasswordMessage.message}
                    </div>
                    <Button
                      variant="contained"
                      type="primary"
                      htmlType="button"
                      onClick={() => handleRecoverPassword()}
                      className="login-form-button recover"
                      size="large"
                    >
                      {intl.formatMessage({
                        id: "recover-button",
                      })}
                    </Button>
                  </div>
                ) : (
                  <div>
                    <KnolarInputPassword itemId="password" form={form} />
                    <div className="forgot-password">
                      <Checkbox
                        checked={state.rememberMe}
                        onClick={() =>
                          setState({
                            rememberMe: !state.rememberMe,
                          })
                        }
                      >
                        {intl.formatMessage({
                          id: "credentials-remember",
                        })}
                      </Checkbox>
                      <a
                        style={{ fontWeight: "bold" }}
                        className="forgot-link"
                        onClick={() => setState({ forgotPassword: true })}
                      >
                        {intl.formatMessage({
                          id: "password-recover",
                        })}
                      </a>
                    </div>
                  </div>
                )}
                {state.forgotPassword ? (
                  <Button
                    variant="contained"
                    type="primary"
                    htmlType="button"
                    className="login-form-button back"
                    size="large"
                    onClick={() =>
                      setState({
                        forgotPassword: false,
                        recoverPasswordMessage: { message: "", error: false },
                      })
                    }
                  >
                    {intl.formatMessage({ id: "password-known" })}
                  </Button>
                ) : (
                  <Form.Item className="form-item">
                    <Button
                      variant="contained"
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                      size="large"
                      loading={processing}
                      aria-label="access-button"
                    >
                      {intl.formatMessage({ id: "access" })}
                    </Button>
                    {onSSO && (
                      <Form.Item>
                        <Button
                          variant="contained"
                          type="secondary"
                          className="login-sso-form-button"
                          size="large"
                          aria-label="sso-button"
                          onClick={onSSO}
                        >
                          {intl.formatMessage({ id: "access-with-sso" })}
                        </Button>
                      </Form.Item>
                    )}
                  </Form.Item>
                )}
              </Form>
            </Col>
          </Row>
          <Row className="footer">
            <Col span={11}>
              {`® ${new Date().getFullYear()} ${intl.formatMessage({
                id: "copyright-text",
              })}`}
            </Col>
            <Col span={11} offset={2}>
              <a href="/public/privacy-policy">
                {intl.formatMessage({
                  id: "privacy-policy",
                })}
              </a>{" "}
              /{" "}
              <a href="/public/cookie-policy" /* target="_blank" */>
                {intl.formatMessage({
                  id: "cookie-policy",
                })}
              </a>
            </Col>
          </Row>
        </Col>
        <Col span={11} className="knolar-info" align="center">
          <Row>
            <div className="image-wrapper">
              <img src={laptop} alt="laptop" />
            </div>
            <div className="welcome-text">
              {intl.formatMessage({ id: "welcome-to-knolar" })}
            </div>
          </Row>
          <Row>
            <Col span={20} offset={2} className="slogan-text">
              <div>Leading</div>
              <div>the new era in data</div>
              <div>knowledge</div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

LoginForm.propTypes = {
  onSubmit: PropTypes.func,
  processing: PropTypes.bool.isRequired,
  headerMessage: PropTypes.string.isRequired,
  headerErrorMessage: PropTypes.string.isRequired,
  onSSO: PropTypes.func,
}

export default Form.create({ name: "normal_login" })(LoginForm)
