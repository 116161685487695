import React, { useState } from "react"
import { useIntl } from "gatsby-plugin-intl"

import {
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@material-ui/core"
import { Form } from "antd"

import eyeOffIcon from "../assets/images/ic_eye_off.png"
import eyeOnIcon from "../assets/images/ic_eye.png"
import errorIcon from "../assets/images/Icon-Form-Error.png"
import successIcon from "../assets/images/Icon-Form-Success.png"

const KnolarInputPassword = ({
  form,
  success,
  itemId,
  itemLabel,
  onChange,
}) => {
  const intl = useIntl()
  const [showPassword, setShowPassword] = useState(false)

  const renderShowPassword = () => {
    return (
      <div
        className="show-password-icon"
        aria-label="toggle password visibility"
        onClick={() => setShowPassword(!showPassword)}
      >
        <img
          src={showPassword ? eyeOnIcon : eyeOffIcon}
          alt={showPassword ? "eye-on-icon" : "eye-off-icon"}
        />
      </div>
    )
  }

  const { getFieldDecorator, getFieldValue, getFieldError } = form

  const renderInputAdornment = itemId => {
    return (
      <InputAdornment position="end">
        {getFieldError(itemId) ? (
          <img src={errorIcon} alt="error-icon" />
        ) : success ? (
          <img src={successIcon} alt="success-icon" />
        ) : itemId !== "confirm-password" ? (
          renderShowPassword()
        ) : (
          ""
        )}
      </InputAdornment>
    )
  }

  const id = itemLabel || itemId

  return (
    <Form.Item>
      <FormControl variant="outlined" className="form-item">
        <InputLabel className="knolar-input-label" htmlFor={id}>
          {intl.formatMessage({ id })}
        </InputLabel>
        {getFieldDecorator(itemId, {
          rules: [
            {
              required: true,
              message: intl.formatMessage({
                id: `form-password-must-input`,
              }),
            },
            {
              validator: () => {
                const password = getFieldValue("password")
                const confirmPassword = getFieldValue("confirm-password")
                if (itemId !== "confirm-password") {
                  if (confirmPassword) {
                    form.validateFields(["confirm-password"])
                  }
                  return true
                }
                return password === confirmPassword
              },
              message: intl.formatMessage({
                id: `form-password-must-equal`,
              }),
            },
          ],
        })(
          <OutlinedInput
            variant="outlined"
            autoComplete="off"
            fullWidth
            id={id}
            type={showPassword ? "text" : "password"}
            endAdornment={renderInputAdornment(itemId)}
            onChange={onChange}
          />
        )}
      </FormControl>
    </Form.Item>
  )
}

export default KnolarInputPassword
