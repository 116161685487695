import React from "react"
import { useIntl } from "gatsby-plugin-intl"

import {
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@material-ui/core"
import { Form } from "antd"

import errorIcon from "../assets/images/Icon-Form-Error.png"

const KnolarInput = ({
  form: { getFieldDecorator, getFieldError },
  itemId,
}) => {
  const intl = useIntl()

  return (
    <Form.Item>
      <FormControl variant="outlined" className="form-item">
        <InputLabel className="knolar-input-label" htmlFor={itemId}>
          {intl.formatMessage({ id: itemId })}
        </InputLabel>
        {getFieldDecorator(itemId, {
          rules: [
            {
              required: true,
              message: intl.formatMessage({
                id: `form-${itemId}-must-input`,
              }),
            },
          ],
        })(
          <OutlinedInput
            fullWidth
            autoComplete="off"
            id={itemId}
            endAdornment={
              <InputAdornment position="end">
                {getFieldError(itemId) ? (
                  <img src={errorIcon} alt="error-icon"></img>
                ) : (
                  ""
                )}
              </InputAdornment>
            }
          />
        )}
      </FormControl>
    </Form.Item>
  )
}

export default KnolarInput
