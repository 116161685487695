import React, { useEffect } from "react"
import { navigate } from "gatsby-plugin-intl"
import queryString from "query-string"
import { message, Row, Spin } from "antd"

import LoginForm from "../components/login/loginForm"
import LoginNewPasswordForm from "../components/login/loginNewPasswordForm"

import {
  AUTH_CONSTANTS,
  confirmNewPassword,
  getSSOUrl,
  isLoggedIn,
  login,
  signInFromSSO,
} from "../services/auth"
import { CONSTANTS } from "../services/constants"
import { getUserPermissions } from "../services/auth/permissions/userPermissions"
import useStateObject from "../hooks/useStateObject"
import { Template } from "../layouts/base"

const { LOGGED_IN_INITIAL_PAGE } = CONSTANTS

const IndexPage = () => {
  const [state, setState] = useStateObject({
    newPasswordRequiredFunction: null,
    username: "",
    password: "",
    displayNewPassword: false,
    isRecoveryCode: false,
    loading: true,
    processing: false,
    headerMessage: "",
    headerErrorMessage: "",
  })

  useEffect(() => {
    ;(async () => {
      const {
        recovery,
        username,
        code,
        error,
        error_description,
      } = queryString.parse(window.location.search, { decode: false })
      if (recovery === "true") {
        setState({
          isRecoveryCode: true,
          username,
          headerMessage: "login-header-recover-password",
          loading: false,
        })
      } else {
        if (error && error_description) {
          message.error(error_description)
          setState({ loading: false })
        } else {
          if (code) {
            await signInFromSSO(code)
          }
          try {
            const isLogged = await isLoggedIn()
            if (isLogged && state.newPasswordRequiredFunction === null) {
              const permissions = await getUserPermissions()
              if (permissions.length > 0) {
                setTimeout(() => {
                  navigate(LOGGED_IN_INITIAL_PAGE)
                }, 1000)
              }
            } else {
              setState({ loading: false })
            }
          } catch (err) {
            setState({ loading: false })
            console.log(err)
          }
        }
      }
    })()
  }, [])

  const clearHeaderErrorMessage = () => {
    setState({
      headerErrorMessage: "",
    })
  }

  const onLoginFormSubmit = ({ username, password }) => {
    clearHeaderErrorMessage()
    setState({ processing: true })
    login(username, password)
      .then(() => {
        setState({
          username: "",
          password: "",
          headerErrorMessage: "",
          headerMessage: "",
          displayNewPassword: false,
          processing: false,
        })
        navigate(LOGGED_IN_INITIAL_PAGE)
      })
      .catch(reason => {
        let stateValues = { processing: false }
        const { code } = reason
        if (code === AUTH_CONSTANTS.NEW_PASSWORD_ERROR) {
          const { newPasswordRequiredFunction } = reason
          stateValues = {
            ...stateValues,
            newPasswordRequiredFunction,
            displayNewPassword: true,
            headerMessage: "login-header-first-password",
          }
        } else if (code === AUTH_CONSTANTS.NO_USER_ERROR) {
          stateValues = {
            ...stateValues,
            headerErrorMessage: "login-header-user-does-not-exist",
          }
        } else if (code === AUTH_CONSTANTS.PASSWORD_IS_WRONG) {
          stateValues = {
            ...stateValues,
            headerErrorMessage: "login-user-or-pass-incorrect",
          }
        } else if (code === AUTH_CONSTANTS.INVALID_FORMAT) {
          stateValues = {
            ...stateValues,
            headerErrorMessage: "login-user-or-pass-invalid-format",
          }
        } else if (code === AUTH_CONSTANTS.EXPIRED_CODE) {
          stateValues = {
            ...stateValues,
            headerErrorMessage: "temp-password-expired",
          }
        } else {
          stateValues = {
            ...stateValues,
            headerErrorMessage: "login-user-or-pass-incorrect",
          }
        }
        setState(stateValues)
      })
  }

  const onNewPasswordFormSubmit = ({ password, recoverycode }) => {
    clearHeaderErrorMessage()
    const { newPasswordRequiredFunction, username } = state
    if (typeof newPasswordRequiredFunction === "function") {
      let stateValues = { processing: true }
      newPasswordRequiredFunction(password)
        .then(() => {
          setState({
            headerMessage: "login-header-input-user-and-new-password",
            displayNewPassword: false,
            processing: false,
          })
        })
        .catch(reason => {
          stateValues = { processing: false }
          if (
            reason.code === AUTH_CONSTANTS.PASSWORD_DOES_NOT_MEET_CRITERIA_ERROR
          ) {
            stateValues = {
              ...stateValues,
              headerErrorMessage: "login-header-password-not-comply",
            }
          }
        })
      setState(stateValues)
    } else {
      confirmNewPassword(username, recoverycode, password, err => {
        if (!err) {
          setState({
            headerMessage: "login-header-input-user-and-new-password",
            displayNewPassword: false,
            isRecoveryCode: false,
            headerErrorMessage: "",
          })
        } else {
          let headerErrorMessage = "password-recovery-error-message"
          switch (err.code) {
            case AUTH_CONSTANTS.LIMIT_EXCEEDED_EXCEPTION:
              headerErrorMessage =
                "password-recovery-error-message-too-much-attempts"
              break
            case AUTH_CONSTANTS.INVALID_FORMAT:
              headerErrorMessage = "login-header-password-not-comply"
              break
            case AUTH_CONSTANTS.EXPIRED_CODE:
              headerErrorMessage =
                "password-recovery-error-message-verification-code-expired"
              break
            case AUTH_CONSTANTS.INVALID_CODE:
              headerErrorMessage = "password-recovery-invalid-code"
              break
            case "UserNotFoundException":
              headerErrorMessage = "password-recovery-error-message-not-found"
              break
          }
          setState({ headerErrorMessage })
        }
      })
    }
  }

  const {
    displayNewPassword,
    headerErrorMessage,
    headerMessage,
    isRecoveryCode,
    loading,
    processing,
  } = state

  if (loading) {
    return (
      <Template>
        <Row
          style={{
            height: "200px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin size="large" />
        </Row>
      </Template>
    )
  }

  if (displayNewPassword || isRecoveryCode) {
    return (
      <LoginNewPasswordForm
        onSubmit={onNewPasswordFormSubmit}
        headerErrorMessage={headerErrorMessage}
        headerMessage={headerMessage}
        isRecoveryCode={isRecoveryCode}
        processing={processing}
      />
    )
  }

  const handleSSO = () => {
    window.location = getSSOUrl("authorize")
  }

  return (
    <LoginForm
      username={state.username}
      password={state.password}
      onSubmit={onLoginFormSubmit}
      headerErrorMessage={headerErrorMessage}
      headerMessage={headerMessage}
      processing={processing}
      onSSO={
        process.env.GATSBY_COGNITO_SSO_ENABLED === "true" ? handleSSO : null
      }
    />
  )
}

export default IndexPage
